<template>
  <div class="domain-detail-info">
    <page-block :title="$t('title.info')" class="domain-detail-info__block">
      <main-card>
        <div class="standart-title">
          {{ current.pricelist }}
        </div>
        <!--        <div v-if="current.createdate" class="note-text">-->
        <!--          {{ $t('cost') + ': ' + costStr }}-->
        <!--        </div>-->
        <div v-if="current.createdate" class="note-text">
          {{ $t('created', { date: $d(current.createdate, 'short') }) }}
        </div>
        <div class="domain-detail-view__promise">
          <div class="note-text">
            {{ $t('activeUnder', { date: $d(current.expiredate, 'short') }) }}
          </div>
          <plain-button
            v-if="tariff.promise !== 'null'"
            v-tooltip="{
              content: promiseOn,
              autoHide: false,
              placement: 'top-start',
              container: false,
            }"
            icon="promise-icon"
            class="allowed-user-rights__promise"
          />
        </div>
        <template v-if="hasProlongTool || hasChangepricelistTool" #bodyEnd>
          <div class="domain-info__btns">
            <base-button class="domain-info__btn" @click="goToDnsManager">
              {{ $t('actions.dnsmanager') }}
            </base-button>
            <base-button
              v-if="hasProlongTool"
              class="domain-info__btn"
              @click="cost === 0 ? singleProlongFree(current) : singleProlong(current)"
            >
              {{ $t('actions.prolong') }}
            </base-button>
            <base-button
              v-if="hasChangepricelistTool"
              theme="outlined"
              class="domain-info__btn"
              @click="changepricelist"
            >
              {{ $t('actions.changePlan') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <page-block
      v-if="hasAutoprolong && autoprolongSettings"
      :title="$t('title.autoprolong')"
      class="domain-detail-info__block"
    >
      <main-card>
        <div class="standart-text">
          {{
            $t(`autoprolong.state.${autoprolongSettings.state}`, {
              period: autoprolongSettings.period,
            })
          }}
        </div>
        <template #bodyEnd>
          <div class="domain-autoprolong__btns">
            <base-button
              class="domain-autoprolong__btn"
              @click="autoprolong(current, autoprolongSettings.state === 'on' ? 'update' : 'on')"
            >
              {{
                autoprolongSettings.state === 'on'
                  ? $t('autoprolong.actions.update')
                  : $t('autoprolong.actions.on')
              }}
            </base-button>
            <base-button
              v-if="autoprolongSettings.state === 'on'"
              theme="outlined"
              class="domain-autoprolong__btn"
              @click="autoprolong(current, 'off')"
            >
              {{ $t('autoprolong.actions.off') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <page-block
      v-if="dnsHostTariffAddons.length"
      :title="$t('title.addons')"
      class="domain-detail-info__block"
    >
      <main-card class="domain-detail-info__addons standart-text">
        <div
          v-for="(item, i) in dnsHostTariffAddons"
          :key="i"
          class="domain-detail-info__addon l-flex"
        >
          <div class="l-col-12_sm-4 domain-detail-info__addon-label standart-title">
            {{ item.label }}
            <plain-button
              v-if="item.hint"
              v-tooltip="{ content: item.hint, placement: 'top-start', container: false }"
              icon="help"
              size="small"
              color="dim"
              tabindex="-1"
              class="domain-detail-info__addon-hint"
            />
          </div>
          <div class="l-col-12_sm-8 domain-detail-info__addon-value">
            {{ item.value }}
          </div>
        </div>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import PageBlock from '@/components/PageBlock/PageBlock.vue';
import MainCard from '@/components/MainCard/MainCard.vue';
import DomainProlongSingle from '../../../components/DomainProlongSingle.vue';
import changePricelist from '@/mixins/billmgr/changePricelist';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongAuto from '@/mixins/billmgr/prolongAuto';
import { DomainTariff } from '@/models/BillMgr/DomainTariff';
import { DnsHostTariff } from '@/models/BillMgr/DnsHostTariff';
import { DnsHost } from '@/models/DnsMgr/DnsHost';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import DnsServiceProlongSinglePayments from '@/layouts/Domains/components/DnsServiceProlongSinglePayments';
import DnsServiceProlongSingle from '@/layouts/Domains/components/DnsServiceProlongSingle';
// import { IspTool } from '@/models/base/IspTools';
// import { BillMgrTool } from '@/models/BillMgr/Tools';
// import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import qs from 'qs';
import prolongText from '@/mixins/prolongText';
export default {
  name: 'DnsDetailInfo',
  components: {
    PageBlock,
    MainCard,
  },
  mixins: [changePricelist, prolongSingle, prolongSingleFree, prolongAuto, prolongText],
  props: {
    tariff: {
      type: [DomainTariff, DnsHostTariff, DnsHost],
      required: true,
      validator: obj =>
        obj instanceof DomainTariff || obj instanceof DnsHostTariff || obj instanceof DnsHost,
    },
    moduleMain: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      moduleOrder: 'moduleDomainsOrder',
      itemtype: 'dnsService',
      singleProlongComponentFirst: DnsServiceProlongSingle,
      singleProlongComponent: DnsServiceProlongSinglePayments,
    };
  },
  computed: {
    current() {
      return this.tariff;
    },
    dnsHostTariffAddons() {
      return this.tariff.configurator.reduce((list, addon) => {
        if (addon.config.readonly || addon.config.disabled) {
          const item = { label: addon.label, value: addon.config.value };
          if (addon.hint) item.hint = addon.hint;
          if (addon.config.measure) item.value += ' ' + addon.config.measure;
          list.push(item);
        }
        return list;
      }, []);
    },
    costStr() {
      return this.$n(this.current.specs.cost, 'currency') + ' / ' + this.periodStr;
    },
    periodStr() {
      const { periodLen, periodType } = this.current.specs.periodTransformed;
      return this.$tc(`period.${periodType}_c`, periodLen);
    },
    cost() {
      return this.current.cost;
    },
    tools() {
      const splitted = this.moduleMain.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path.tools;
    },
    hasChangepricelistTool() {
      return (
        !!this.tools &&
        !!this.tools.changepricelist &&
        this.tools.changepricelist.isEnable(this.tariff)
      );
    },
    hasProlongTool() {
      return (
        this.current.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.current)
      );
    },
    hasAutoprolong() {
      return this.current.autoprolongAvailable;
    },
    autoprolongSettings() {
      return this.current.autoprolongSettings;
    },
  },
  methods: {
    goToDnsManager() {
      let modal = null;
      this.$modals.open({
        name: 'ToPleskPanel',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleVhost/fetchBillMgrToolAction', {
          func: 'gotoserver',
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            this.$modals.close();
            const stringUrl = data.ok.v.split('?');
            const url = qs.parse(stringUrl[1]);
            // url.backurl = `${localStorage.getItem('urlHash')}vhost`;
            url.backurl = window.location.href;
            // url.backurl = dev
            //   ? `${localStorage.getItem('urlHash')}vhost`
            //   : `${this.$store.state.moduleApp.url}/#/vhost`;
            const newUrl = stringUrl[0] + '?' + qs.stringify(url);
            // console.log(window.location.href);
            // console.log(url);
            window.open(newUrl);
            // console.log(newUrl);
            // console.log(data.ok.v);
            // console.log(qs.stringify(url));
            // window.open(data.ok.v);
          } else {
            this.$modals.close();
            throw new Error(
              'Не удалось перейти в плеск панель. Возможно у Вас выключен сервер. Попробуйте обратиться в техподдержку'
            );
          }
        })
        .catch(e => {
          this.$modals.close();
          this.showError(e);
        });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "title": {
      "plan": "Тарифный план",
      "info": "Основная информация",
      "addons": "Дополнительная информация",
      "stats": "Статистика",
      "autoprolong": "Автопродление"
    },
    "activeUnder": "Действует до {date}",
    "created": "Создан {date}",
    "cost": "Стоимость",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф",
      "dnsmanager": "Перейти в панель"
    },
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "confirm": "Оплатить",
      "free": "Активировать",
      "cancel": "Отмена",
      "order": "В корзину"
    },
    "autoprolong": {
      "title": "Автопродление",
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      },
      "actions": {
        "on": "Включить",
        "off": "Выключить",
        "update": "Изменить"
      },
      "modals": {
        "off": {
          "title": "Отключить автопроделние",
          "desc": "Автопродление для услуги #{id} будет отключено",
          "confirm": "Да, отключить"
        },
        "update": {
          "title": "Изменить настройки автопродления",
          "confirm": "Сохранить"
        },
        "on": {
          "title": "Включить автопродление",
          "confirm": "Включить"
        }
      },
      "result": {
        "success": {
          "title": "Отправлено успешно",
          "desc": "Настройки автопродления для услуги #{id} были успешно изменены",
          "confirm": "Хорошо"
        },
        "error": {
          "title": "Произошла ошибка",
          "desc": "Настройки автопродления для услуги #{id} изменить не удалось. Попробуйте позднее или обратитесь за помощью в поддержку",
          "confirm": "Понятно"
        }
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domain-detail-view{
  &__promise{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.allowed-user-rights{
  &__promise{
    margin-left: 1px;
  }
}

.domain {
  &-plan,
  &-info,
  &-autoprolong {

    &__btn {
      min-width: 160px;
      & + & {
        margin-top: 14px;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
        +breakpoint(sm-and-down) {
          margin-left: 1.5rem;
        }
      }
    }
  }

  &-detail-info {
    &__addon {
      &-hint {
        margin: -0.25rem 0.25rem;
        vertical-align: middle;
      }
    }
    //&__btn {
    //  margin-right: 1.5rem;
    //}
  }
}
</style>
