<template>
  <div class="domains-main">
    <transition name="fade" mode="out-in">
      <div class="domains-main__body">
        <transition name="slide-fade">
          <router-view v-if="current" :tariff="current" :module-main="moduleMain"></router-view>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DnsService',
  components: {},
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsDnsHost',
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.dnsList;
    },
    current() {
      return this.list[0];
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domains-main {
  &__title {
    flexy(space-between, center);
    margin-bottom: 1.5rem;
  }
  &__body {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
